<template>
  <div id="walletOrder">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>钱包订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="endTimeChange"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('walletOrder-export')"
              >导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="orderId" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="bindPhone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="amountChange" label="金额变动" align="center">
        </el-table-column>
        <el-table-column prop="recordType" label="记录类型" align="center">
        </el-table-column>
        <el-table-column prop="recordTime" label="变动时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <exportDialog ref="exportDialog" />
  </div>
</template>
<script>
export default {
  components: {
    exportDialog: () => import("./exportDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        startTime: "",
        endTime: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    endTimeChange(value) {
      Object.assign(this.filtrate, {
        endTime: this.$moment(this.$moment(value).format("YYYY-MM-DD 23:59:59"))
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      this.getList(1);
    },
    exportExcel() {
      this.$refs.exportDialog.form = {
        time: [
          this.filtrate.startTime ? this.filtrate.startTime : "",
          this.filtrate.endTime ? this.filtrate.endTime : "",
        ],
        parkingLotId: this.filtrate.parkingLotId,
      };
      this.$refs.exportDialog.handleShow();
    },
    reset() {
      this.filtrate = {
        keyword: "",
        startTime: "",
        endTime: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/order/user/list", {
          ...this.filtrate,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.recordTime = this.$moment(item.recordTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#walletOrder {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .exportBtn {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .orderId {
    cursor: pointer;
  }
}
</style>
